import { useRef, useState } from 'react';
import { Button, Form, GetProps, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { useQuillFormItem } from '@gowgates/core';
import { t } from '@gowgates/utils';

import { NewPlaceholderModal } from './NewPlaceholderModal';

type AntFormItemProps = GetProps<typeof Form.Item>;
type EmailTemplateEditorProps = Pick<AntFormItemProps, 'id'> & {
  toolbarId?: string;
};

const FORMATS = [
  'header',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'direction',
  'list',
  'indent',
  'link',
  'image',
  'color'
];

export const EmailTemplateEditor = ({ id, toolbarId = 'toolbar' }: EmailTemplateEditorProps) => {
  const form = Form.useFormInstance();

  const editorPositionRef = useRef(0);
  const [showPlaceholderModal, setShowPlaceholderModal] = useState(false);

  const { quillRef, quill } = useQuillFormItem({
    formItemId: id,
    modules: { toolbar: `#${toolbarId}` },
    formats: FORMATS
  });

  const hasError = form && form.getFieldError(id).length > 0;

  return (
    <div>
      <NewPlaceholderModal
        open={showPlaceholderModal}
        onClose={() => setShowPlaceholderModal(false)}
        addPlaceholder={(placeholderText) => {
          if (!quill) return;

          quill.insertText(editorPositionRef.current, placeholderText);
          quill.setSelection(editorPositionRef.current + placeholderText.length, 0);
        }}
      />

      <div className={`quill${hasError ? ' error' : ''}`}>
        <div id={toolbarId}>
          <div className="d-flex justify-content-between">
            <div>
              <span className="ql-formats">
                <select className="ql-header" defaultValue="3">
                  <option value="1">Heading</option>
                  <option value="2">Subheading</option>
                  <option value="3">Normal</option>
                </select>
              </span>

              <span className="ql-formats">
                <button type="button" role="menu" className="ql-bold" aria-label="Bold" />
                <button type="button" role="menu" className="ql-italic" aria-label="Italic" />
                <button type="button" role="menu" className="ql-underline" aria-label="Underline" />
                <button type="button" role="menu" className="ql-strike" aria-label="Strike" />
              </span>

              <span className="ql-formats">
                <button
                  type="button"
                  role="menu"
                  className="ql-list"
                  value="ordered"
                  aria-label="Ordered"
                />
                <button
                  type="button"
                  role="menu"
                  className="ql-list"
                  value="bullet"
                  aria-label="Bullet"
                />
                <button
                  type="button"
                  role="menu"
                  className="ql-indent"
                  value="-1"
                  aria-label="Indent less"
                />
                <button
                  type="button"
                  role="menu"
                  className="ql-indent"
                  value="+1"
                  aria-label="Indent more"
                />
              </span>

              <span className="ql-formats">
                <select className="ql-align" aria-label="Align" />
                <select className="ql-color" aria-label="Color" />
                <select className="ql-background" aria-label="Background" />
              </span>

              <span className="ql-formats">
                <button type="button" role="menu" className="ql-link" aria-label="Link" />
                <button type="button" role="menu" className="ql-image" aria-label="Image" />
              </span>

              <span className="ql-formats">
                <Tooltip title={t('globals.add_placeholder')}>
                  <Button
                    onClick={() => {
                      if (!quill) return;

                      const range = quill.getSelection();
                      editorPositionRef.current = (range && range.index) || 0;

                      setShowPlaceholderModal(true);
                    }}
                    icon={<PlusOutlined />}
                    aria-label={t('globals.add_placeholder')}
                  />
                </Tooltip>
              </span>
            </div>
          </div>
        </div>

        <div ref={quillRef} />
      </div>
    </div>
  );
};
