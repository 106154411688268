import { useCallback } from 'react';
import Cookies from 'js-cookie';

import { apiConfigs } from '@gowgates/api-client';

import { User } from '../../types';

export const useAuth = () => {
  const userData = localStorage.getItem('user');
  const currentUser = userData ? (JSON.parse(userData) as User & Record<string, any>) : undefined;
  const isUserSignedIn = !!currentUser;

  const clearUserFromStorage = () => {
    localStorage.removeItem('user');
    Cookies.remove(apiConfigs().authTokenKey, { domain: apiConfigs().domain });
  };

  const updateUserInStorage = (user: unknown) => {
    localStorage.setItem('user', JSON.stringify(user));
  };

  const addUserToStorage = (user: unknown, authorization?: string) => {
    if (!user || !authorization) return;

    Cookies.set(apiConfigs().authTokenKey, authorization, { domain: apiConfigs().domain });
    localStorage.setItem('user', JSON.stringify(user));
  };

  const setAfterLoginPath = (path: string) => {
    localStorage.setItem('afterLoginPath', path);
  };

  const digestAfterLoginPath = useCallback(() => {
    const afterLoginPath = localStorage.getItem('afterLoginPath') || '/';
    localStorage.removeItem('afterLoginPath');
    return afterLoginPath;
  }, []);

  const handleLogout = () => {
    clearUserFromStorage();
    window.location.href = '/login';
  };

  return {
    currentUser,
    isUserSignedIn,
    clearUserFromStorage,
    addUserToStorage,
    updateUserInStorage,
    setAfterLoginPath,
    digestAfterLoginPath,
    handleLogout
  };
};
