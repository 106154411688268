import { ConfigProvider, Menu, ThemeConfig } from 'antd';
import { Link } from 'react-router-dom';

import { t } from '@gowgates/utils';
import { useAuth, AvatarAndName } from '@gowgates/core';

type UserMenuProps = {
  theme?: ThemeConfig;
};

const UserMenu = ({ theme }: UserMenuProps) => {
  const { currentUser, clearUserFromStorage } = useAuth();

  const handleLogout = () => {
    clearUserFromStorage();
    window.location.href = '/login';
  };

  const items = [
    {
      key: 'name',
      label: <AvatarAndName user={currentUser} />,
      theme: 'light',
      children: [
        { key: 'profile', label: <Link to="/profile">{t('account.title')}</Link> },
        { key: 'logout', label: t('auth.logout'), onClick: handleLogout }
      ]
    }
  ];

  return (
    <ConfigProvider theme={theme}>
      <Menu
        data-testid="account-menu"
        theme="dark"
        mode="horizontal"
        items={items}
        className="main-menu right-menu"
      />
    </ConfigProvider>
  );
};

export default UserMenu;
