import { MouseEventHandler } from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import { Typography } from 'antd';
import { MutationFunction } from '@tanstack/react-query';
import { t } from '@gowgates/utils';
import { FileIcon } from './FileIcon';
import { ExtendedDangerPopconfirm } from './ExtendedDangerPopconfirm';

type FileProps = {
  url?: string;
  type?: string;
  name?: string;
  hasErrors?: boolean;
  disabled?: boolean;
  onRemove?: MouseEventHandler<HTMLInputElement>;
  onDestroy?: MutationFunction;
};

export const File = ({
  url,
  type = '',
  name = '',
  onRemove,
  onDestroy,
  hasErrors = false,
  disabled = false
}: FileProps) => {
  const classNames = `file-field${hasErrors ? ' has-errors' : ''}`;

  return (
    <div className={classNames}>
      <div className="file-field-box">
        <span>
          <FileIcon type={type} />
          {url ? (
            <a href={url} target="_blank" rel="noopener noreferrer">
              {name}
            </a>
          ) : (
            name
          )}
        </span>
        {onRemove && !disabled && (
          <Typography.Link onClick={onRemove} className="remove-file" disabled={disabled}>
            <CloseCircleFilled />
          </Typography.Link>
        )}

        {onDestroy && !disabled && (
          <ExtendedDangerPopconfirm
            title={t('document.confirmDelete')}
            deleteFn={onDestroy}
            permission
            async={false}
          />
        )}
      </div>
    </div>
  );
};
