import { Button, Form, GetProps } from 'antd';
import { SendOutlined } from '@ant-design/icons';

import { t } from '@gowgates/utils';
import { useQuillFormItem } from '../../hooks';

type AntFormItemProps = GetProps<typeof Form.Item>;
type Props = Pick<AntFormItemProps, 'id'> & {
  onCancel?: () => void;
  loading?: boolean;
};

export const QuillWithSend = ({ onCancel, loading = false, id }: Props) => {
  const { quillRef, form } = useQuillFormItem({
    formItemId: id,
    modules: {
      toolbar: '#toolbar'
    },
    formats: ['bold', 'italic', 'underline', 'list']
  });

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13 && (event.ctrlKey || event.metaKey)) {
      form.submit();
    }
  };

  const hasError = form && form.getFieldError(id).length > 0;

  return (
    <div onKeyDown={onKeyDown} className={`quill-with-send${hasError ? ' error' : ''}`}>
      <div ref={quillRef} />

      <div id="toolbar">
        <div className="d-flex justify-content-between">
          <div>
            <span className="ql-formats">
              <button type="button" role="menu" className="ql-bold" aria-label="Bold" />
              <button type="button" role="menu" className="ql-italic" aria-label="Italic" />
              <button type="button" role="menu" className="ql-underline" aria-label="Underline" />
            </span>

            <span className="ql-formats">
              <button
                type="button"
                role="menu"
                className="ql-list"
                value="ordered"
                aria-label="Ordered"
              />
              <button
                type="button"
                role="menu"
                className="ql-list"
                value="bullet"
                aria-label="Bullet"
              />
            </span>
          </div>

          <span className="ql-formats submit-btn">
            {onCancel && <Button onClick={onCancel}>{t('globals.cancel')}</Button>}
            <Button type="primary" icon={<SendOutlined />} htmlType="submit" loading={loading} />
          </span>
        </div>
      </div>
    </div>
  );
};
