import { QUILL_MODULES } from '@gowgates/utils';
import { Form, GetProps } from 'antd';
import { useQuillFormItem } from '../../hooks';

type AntFormItemProps = GetProps<typeof Form.Item>;
type CgwQuillProps = Pick<AntFormItemProps, 'id'> & {
  hasError?: boolean;
  tabIndex?: number;
};

export const CgwQuill = ({ hasError = false, id, tabIndex }: CgwQuillProps) => {
  const { quillRef } = useQuillFormItem({
    formItemId: id,
    modules: QUILL_MODULES,
    formats: ['bold', 'italic', 'underline', 'list']
  });

  return (
    <div className={`quill${hasError ? ' error' : ''}`}>
      <div ref={quillRef} tabIndex={tabIndex} />
    </div>
  );
};
