import PropTypes from 'prop-types';
import { Typography, Dropdown, Space } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { DateFormatter } from '@gowgates/dynamic-fields';
import { UserAvatar } from '@gowgates/core';

import { deleteNote } from '../../api/endpoints';

const Note = ({ note }) => {
  const queryClient = useQueryClient();
  const claimId = Number(useParams().claimId);

  const { mutate } = useMutation({
    mutationFn: () => deleteNote(note.id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['claim', claimId, 'tasks'] });
    }
  });

  const items = note.permissions.destroy
    ? [{ key: 'delete', label: 'Delete note', onClick: mutate }]
    : [];

  return (
    <article className="comment">
      <header>
        <Space>
          <UserAvatar user={note.user} />
          <strong>{note.user.name}</strong>
          <span> — </span>
          <Typography.Text type="secondary">
            <DateFormatter value={note.createdAt} format="minute" />
          </Typography.Text>

          {items.length > 0 && (
            <Dropdown trigger={['click']} placement="bottomRight" menu={{ items }}>
              <MoreOutlined />
            </Dropdown>
          )}
        </Space>
      </header>

      <div className="comment-inner">
        <div className="html-text" dangerouslySetInnerHTML={{ __html: note.text }} />
      </div>
    </article>
  );
};

Note.propTypes = {
  note: PropTypes.object.isRequired
};

export default Note;
