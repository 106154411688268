import { extractData, extractDataAndAuthorization, apiConfigs } from '@gowgates/api-client';
import { User } from '../../../types';

export const client = () => apiConfigs().clientInstance;

// Devise
export const login = (user: unknown) =>
  client()
    .post('/users/session', { data: { user } })
    .then(extractDataAndAuthorization<User>);
export const preAuthenticate = (user: unknown) =>
  client()
    .post('/users/session/pre_authenticate', { data: { user } })
    .then(extractData<{ otpRequiredForLogin: boolean; qrCode: string }>);
export const activateOtpAndSignIn = (user: unknown) =>
  client()
    .post('/users/session/activate_otp_and_sign_in', { data: { user } })
    .then(extractDataAndAuthorization<User>);
export const sendUnlockInstructions = (user: unknown) =>
  client().post('/users/unlock', { data: { user } }).then(extractData);
export const sendConfirmationInstructions = (user: unknown) =>
  client().post('/users/confirmation', { data: { user } }).then(extractData);
export const getOtpQrCode = () => client().get('profile/otps/qr_code').then(extractData);
export const createOtp = (otp: unknown) =>
  client().post('profile/otps', { data: { otp } }).then(extractData);
export const updatePassword = (user: unknown) =>
  client().patch('/profile/password', { data: { user } }).then(extractData);
export const recoverPassword = (user: unknown) =>
  client().post('/users/password', { data: { user } }).then(extractData);
export const resendConfirmation = (user: unknown) =>
  client()
    .post('/users/confirmation', { data: { user } })
    .then(extractData<{ pendingReconfirmation: boolean }>);
export const confirmSelf = (user?: unknown, confirmationToken?: string | null) =>
  !user || !confirmationToken
    ? Promise.reject(new Error('Invalid params'))
    : client()
        .put('/users/confirmation', { data: { user, confirmationToken } })
        .then(extractData<{ qrCode: string; email: string }>);
export const getConfirmationToken = (confirmationToken?: string | null) =>
  !confirmationToken
    ? Promise.reject(new Error('Invalid params'))
    : client()
        .get('/users/confirmation/new', { params: { confirmation_token: confirmationToken } })
        .then(extractData<{ pendingReconfirmation: boolean }>);
export const unlockSelf = (unlockToken: string) =>
  client()
    .get('/users/unlock', { params: { unlock_token: unlockToken } })
    .then(extractData);
export const resendUnlock = (user: unknown) =>
  client().post('/users/unlock', { data: { user } }).then(extractData);
export const getPasswordToken = (passwordToken: string) =>
  client()
    .get('/users/password/new', { params: { reset_password_token: passwordToken } })
    .then(extractData);
export const resetPassword = (user: unknown) =>
  client().put('/users/password', { data: { user } }).then(extractData);
