import { useEffect } from 'react';
import { Form, GetProps } from 'antd';
import { QuillOptions } from 'quill';
import { useQuill } from 'react-quilljs';

type AntFormItemProps = GetProps<typeof Form.Item>;
type Props = QuillOptions & {
  formItemId: AntFormItemProps['id'];
};

export const useQuillFormItem = ({ formItemId, ...quillOptions }: Props) => {
  const form = Form.useFormInstance();

  const quillResults = useQuill(quillOptions);
  const quill = quillResults.quill;

  useEffect(() => {
    if (quill && form && formItemId) {
      quill.clipboard.dangerouslyPasteHTML(form.getFieldValue(formItemId));
    }
  }, [quill, form, formItemId]);

  useEffect(() => {
    if (quill && form && formItemId) {
      quill.on('text-change', () => {
        form.setFieldValue(formItemId, quill.root.innerHTML);
      });
    }
  }, [quill, form, formItemId]);

  return { form, ...quillResults };
};
