import React from 'react';
import PropTypes from 'prop-types';
import { ColorPicker, Input, Radio, Form } from 'antd';
import { FormItem } from '@gowgates/dynamic-fields';
import { booleanOptions } from '@gowgates/utils';

const TenantDetailsForm = ({ disabled = false }) => {
  const form = Form.useFormInstance();

  return (
    <>
      <FormItem name={['name']} model="tenant" required>
        <Input />
      </FormItem>
      <FormItem name="primaryColor" model="tenant">
        <ColorPicker
          disabled={disabled}
          disabledAlpha
          onChange={(v, hex) => form.setFieldValue('primaryColor', hex)}
        />
      </FormItem>
      <FormItem name="bgColor" model="tenant">
        <ColorPicker
          disabled={disabled}
          disabledAlpha
          onChange={(v, hex) => form.setFieldValue('bgColor', hex)}
        />
      </FormItem>
      <FormItem name={['replyEmail']} model="tenant">
        <Input />
      </FormItem>
      <FormItem name="forceOtp" model="tenant">
        <Radio.Group options={booleanOptions()} />
      </FormItem>
    </>
  );
};

TenantDetailsForm.propTypes = {
  disabled: PropTypes.bool
};

export default TenantDetailsForm;
