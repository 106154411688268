import React from 'react';
import { Form, Row, Col, Button, App, Input } from 'antd';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FormItem } from '@gowgates/dynamic-fields';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { useAuth } from '@gowgates/core';
import { usePageTitle } from '@gowgates/core';
import { getOtpQrCode, updateUser } from '../../api/endpoints';

const Profile = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData(['users', 'me']);
  const { updateUserInStorage } = useAuth();
  const [form] = Form.useForm();
  usePageTitle(t('account.title'));

  const { data: svg } = useQuery({
    queryKey: ['qrCode'],
    queryFn: getOtpQrCode,
    enabled: user.otpRequiredForLogin
  });

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values) => updateUser(user.id, values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      message.success('Profile updated');
      updateUserInStorage(data);
    }
  });

  return (
    <Form form={form} layout="vertical" onFinish={mutate} initialValues={user} disabled={isLoading}>
      <Row gutter={30}>
        <Col span={12}>
          <FormItem name="firstName" model="user" required>
            <Input />
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem name="lastName" model="user" required>
            <Input />
          </FormItem>
        </Col>
      </Row>

      {user.otpRequiredForLogin && (
        <>
          <p>{t('user.otp.enabled')}</p>
          <p>{t('user.otp.useDevice')}</p>

          {/* eslint-disable-next-line react/no-danger */}
          <div className="text-center" dangerouslySetInnerHTML={{ __html: svg }} />
        </>
      )}

      <footer className="d-flex justify-content-end">
        <Button type="primary" htmlType="submit">
          {t('globals.save')}
        </Button>
      </footer>
    </Form>
  );
};

export default Profile;
