import { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { ConfigProvider, ThemeConfig, App as AntApp } from 'antd';
import merge from 'lodash.merge';
import axios from 'axios';
import { QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { apiConfigs, defaultQueryClient } from '@gowgates/api-client';
import { setTranslations } from '@gowgates/utils';

import { SuspenseWrapper } from '../SuspenseWrapper';
import { loadDefaultTheme } from '../../utils';

import 'antd/dist/reset.css';
import 'quill/dist/quill.snow.css';
import '../../styles/index.scss';

import { AuthProvider } from '../../layouts/auth/context/AuthProvider';
import { AuthConfigs } from '../../layouts';
import { BrandConfigs } from '../../types';
import { BrandConfigsProvider } from '../../contexts';
import { Loader } from '../Loader';

type AppWrapperProps = PropsWithChildren & {
  router?: ReturnType<typeof createBrowserRouter>;
  themeOverrides?: Partial<ThemeConfig>;
  loaderDelay?: number;
  authConfigs?: AuthConfigs;
  brandConfigs?: BrandConfigs;
};

const RouterWrapper = ({ router }: Pick<AppWrapperProps, 'router'>) => {
  if (!router) return null;

  return <RouterProvider router={router} />;
};

export const AppWrapper = ({
  children,
  loaderDelay,
  themeOverrides,
  router,
  authConfigs,
  brandConfigs
}: AppWrapperProps) => {
  const [isLoadingTranslations, setLoadingTranslations] = useState(false);
  const translationsRequestedRef = useRef(false);

  useEffect(() => {
    if (translationsRequestedRef.current) return;

    (async () => {
      translationsRequestedRef.current = true;
      setLoadingTranslations(true);

      const response = await axios.get(`${apiConfigs().baseURL}/translations.json`);
      setTranslations(response.data);

      setLoadingTranslations(false);
    })();
  }, []);

  const appTheme = useMemo(() => {
    const defaultTheme = loadDefaultTheme();
    if (!themeOverrides) return defaultTheme;
    return merge(defaultTheme, themeOverrides);
  }, [themeOverrides]);

  return (
    <SuspenseWrapper loaderDelay={loaderDelay}>
      <ConfigProvider theme={appTheme}>
        <AntApp>
          <BrandConfigsProvider initialConfigs={brandConfigs}>
            <AuthProvider configs={authConfigs}>
              <QueryClientProvider client={defaultQueryClient}>
                {isLoadingTranslations ? (
                  <Loader />
                ) : (
                  <>
                    <RouterWrapper router={router} />
                    {children}
                  </>
                )}
              </QueryClientProvider>
            </AuthProvider>
          </BrandConfigsProvider>
        </AntApp>
      </ConfigProvider>
    </SuspenseWrapper>
  );
};
